@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
:root {
	--blue: #0c73f0;
	--orange: #fdb24c;
	--grey: #515b60;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.theme-light {
	--color-primary: #0c73f0;
	--color-secondary: #515b60;
	--color-accent: #fdb24c;
	--background-color: white;
	--drop-shadow: drop-shadow(0 0 16px rgba(12, 115, 240, 0.16));
	/*box shadow is for safari as dropshadow is not working on it*/
	--box-shadow: 0 0 16px rgba(12, 115, 240, 0.16);
	--footer-color: #0c73f0;
}

.theme-dark {
	--color-primary: #cecece;
	--color-secondary: #898989;
	--color-accent: #fdb24c;
	--background-color: #1a1a1a;
	--drop-shadow: drop-shadow(0 0 20px rgba(253, 178, 76, 0.16));
	/*box shadow is for safari as dropshadow is not working on it*/
	--box-shadow: 0 0 16px rgba(253, 178, 76, 0.16);
	--footer-color: #fdb24c;
}

html,
body {
	margin: 0;
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--background-color);
}

main {
	margin: 0 auto;
	max-width: 90vw;
	overflow: hidden;
}

.container {
	padding: 8rem 0 8rem 0;
	max-width: 990px;
	margin: auto;
}

h1 {
	font-size: 4rem;
	color: var(--color-primary);
}
h2 {
	font-size: 2rem;
	color: var(--color-primary);
}
h3 {
	font-size: 1.4rem;
	color: var(--color-primary);
}
.reverse {
	color: white;
}
p {
	font-size: 1.1rem;
	color: var(--color-secondary);
	line-height: 1.5rem;
}
label {
	color: var(--color-primary);
	font-weight: 700;
}
.underline {
	background-color: var(--color-accent);
	height: 2.5px;
	width: 5rem;
	margin: -1.2rem auto;
}
.hero {
	font-size: 1.5rem;
	color: var(--color-secondary);
	line-height: 2rem;
	margin-bottom: 3rem;
}
.btn-main {
	font-size: 1.2rem;
	font-weight: 700;
	color: var(--color-primary);
	background-color: var(--background-color);
	border-color: var(--color-primary);
	border-width: 3px;
	text-align: center;
	padding: 10px 20px 10px 20px;
	border-radius: 30px;
}
.btn-main:hover {
	color: var(--background-color);
	background-color: var(--color-primary);
	cursor: pointer;
	transition: 200ms ease-in-out;
}
.content-center {
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.text-center {
	text-align: center;
}
.text-justify-center {
	text-align: center;
	max-width: 60ch;
	margin: 0 auto;
}
.center {
	margin: 0 auto;
}
.align-center {
	align-items: center;
}
/* NAVBAR */
nav {
	display: flex;
	flex-direction: row;
	justify-content: center;
	position: sticky;
	top: 0px;
	background-color: var(--background-color);
	max-width: 990px;
	padding-top: 20px;
	margin: auto;
	z-index: 9999;
}
.nav-container {
	display: flex;
	flex-direction: row;
	width: 90vw;
	justify-content: space-between;
	align-items: center;
}
.logo {
	width: 120px;
}
.logo-parent svg {
	fill: var(--color-primary);
	cursor: pointer;
}
.links-container {
	list-style-type: none;
	display: grid;
	grid-template-columns: auto auto auto auto;
	grid-column-gap: 50px;
	-webkit-column-gap: 50px;
	        column-gap: 50px;
	align-items: center;
}
.nav-link-container {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.nav-links {
	display: flex;
	font-size: 1.3rem;
	text-decoration: none;
	font-weight: 700;
	color: var(--color-primary);
	cursor: pointer;
}
.nav-link-underline {
	width: 0%;
	height: 3px;
	background-color: var(--color-accent);
	transition: width 500ms ease-in-out;
}
.nav-link-container:hover .nav-link-underline {
	width: 100%;
}
/* .nav-burger-icon {
	display: none;
} */
.hamburger-line {
	display: none;
}
.links-container-mob {
	display: none;
	flex-direction: column;
	align-items: flex-end;
	position: fixed;
	background-color: var(--background-color);
	box-shadow: 0 5px 16px rgb(12 115 240 / 0.16);
	width: 100vw;
	z-index: 3;
	overflow: hidden;
}
.nav-links-mob {
	list-style-type: none;
	font-size: 0.8rem;
	line-height: 3rem;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-right: 4vw;
}
.nav-btn {
	display: none;
}
/* HEADER */
header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.hero-content {
	max-width: 60ch;
}
.hero-img {
	display: flex;
	margin-left: auto;
}
/* ABOUT */
.title {
	padding-bottom: 50px;
}
.justify-center {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.padding-top {
	padding-top: 30px;
}
.margin-top {
	margin-top: 30px;
}
/* TECH STACK */
.stack-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, 120px);
	grid-row-gap: 40px;
	justify-content: space-around;
	align-items: center;
	justify-items: center;
	padding-top: 50px;
}
.stack-icon {
	width: 50px;
}
.stack-icon:hover {
	-webkit-filter: none;
	        filter: none;
	transition: 200ms ease-in-out;
}
/* PROJECTS */
.grid-2-col {
	display: grid;
	grid-template-columns: repeat(auto-fit, 490px);
	justify-content: center;
	justify-items: center;
}
/* CARDS */
.card-parent {
	width: 100%;
	display: flex;
	justify-content: center;
}
.card {
	margin-top: 50px;
	max-width: 400px;
	overflow: hidden;
	background-color: var(--background-color);
	-webkit-filter: var(--drop-shadow);
	        filter: var(--drop-shadow);
	-webkit-box-shadow: var(--box-shadow);
	padding: 20px 20px 20px 20px;
	border-radius: 20px;
}
.card-img-container {
	height: 280px;
	overflow: hidden;
}
.card-img {
	object-fit: cover;
	max-width: 400px;
}
.card-links {
	display: flex;
}
.card-btn {
	font-size: 1rem;
	font-weight: 700;
	color: var(--color-primary);
	background-color: var(--background-color);
	border-color: var(--color-primary);
	border-width: 3px;
	text-align: center;
	padding: 5px 10px 5px 10px;
	margin-right: 20px;
	border-radius: 20px;
}
.card-btn:hover {
	color: var(--background-color);
	background-color: var(--color-primary);
	cursor: pointer;
}
.card-tags {
	display: flex;
}
.tag {
	background-color: var(--color-accent);
	margin: 20px 10px 20px 0;
	padding: 3px 7px 3px 7px;
	color: var(--background-color);
	border-radius: 15px;
}
a {
	color: inherit;
	text-decoration: none;
}
/* FORM */

.form-style {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
}
.input-field {
	width: 30rem;
	border-width: 0 0 2px 0;
	color: var(--color-primary);
	font-size: 1rem;
	border-bottom-color: var(--color-primary);
	background-color: var(--background-color);
	padding: 10px 0 10px 0;
}
textarea {
	font-family: "Roboto", sans-serif;
	margin-top: 5px;
}
/*FOOTER*/
.footer {
	background-color: var(--footer-color);
	height: 60px;
	width: 100%;
}
.footer-icon {
	margin-left: 30px;
	width: 25px;
}
/*MEDIA QUERY*/
@media screen and (max-width: 768px) {
	main {
		max-width: 95vw;
	}
	.container {
		padding: 2rem 0 2rem 0;
	}
	h1 {
		font-size: 2rem;
	}
	h2 {
		font-size: 1.4rem;
	}
	h3 {
		font-size: 1rem;
	}
	p {
		font-size: 0.8rem;
		line-height: 1.5rem;
	}
	.hero {
		font-size: 1rem;
		margin-bottom: 1.2rem;
	}
	.btn-main {
		font-size: 1rem;
		border-width: 2px;
		padding: 5px 10px 5px 10px;
	}
	/*NAVBAR*/
	.nav-container {
		width: 95vw;
		align-items: flex-end;
	}
	nav {
		align-items: center;
	}
	.links-container {
		display: none;
	}
	.logo {
		width: 100px;
	}
	/* .nav-burger-icon {
		display: flex;
		width: 25px;
	} */
	.nav-btn {
		display: block;
		background-color: var(--background-color);
		border: none;
	}
	.hamburger-line {
		width: 35px;
		height: 3px;
		background-color: var(--color-primary);
		display: block;
		margin: 8px auto;
		transition: all 0.3s ease-in-out;
	}
	.hamburger:hover {
		cursor: pointer;
	}
	.is-active:hover {
		cursor: pointer;
	}
	/* ONE */

	#hamburger-1.is-active .hamburger-line:nth-child(2) {
		opacity: 0;
	}

	#hamburger-1.is-active .hamburger-line:nth-child(1) {
		-webkit-transform: translateY(9px) rotate(45deg);
		transform: translateY(9px) rotate(45deg);
	}

	#hamburger-1.is-active .hamburger-line:nth-child(3) {
		-webkit-transform: translateY(-13px) rotate(-45deg);
		transform: translateY(-13px) rotate(-45deg);
	}
	.links-container-mob {
		display: flex;
	}
	/*STACK*/
	.stack-container {
		grid-template-columns: repeat(auto-fit, 100px);
		grid-row-gap: 25px;
		padding-top: 30px;
	}
	.stack-icon {
		width: 30px;
		-webkit-filter: none;
		        filter: none;
	}

	/*CARDS*/
	.card-btn {
		font-size: 0.8rem;
		border-width: 2px;
		padding: 3px 7px 3px 7px;
	}
	.tag {
		margin: 20px 10px 10px 0;
		padding: 3px 7px 3px 7px;
		font-size: smaller;
	}
	/*FORM*/
	.input-field {
		width: 25rem;
	}
}
@media screen and (max-width: 480px) {
	.logo {
		width: 70px;
	}
	.hamburger-line {
		width: 25px;
		height: 2px;
	}
	#hamburger-1.is-active .hamburger-line:nth-child(1) {
		-webkit-transform: translateY(7px) rotate(45deg);
		transform: translateY(7px) rotate(45deg);
	}
	.hero-img {
		display: none;
	}
	.card {
		margin: 50px 10px 0px 10px;
		max-width: 350px;
		padding: 10px 10px 10px 10px;
	}
	.grid-2-col {
		grid-template-columns: repeat(auto-fit, minmax(200px, 400px));
	}
	/*FORM*/
	.contact-form {
		width: 90%;
	}
	.input-field {
		width: 100%;
	}
}

/*THEME SWITCH SLIDER SORCERY*/
/* The switch - the box around the slider */

.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 40px;
	width: 40px;
	left: 0px;
	bottom: 4px;
	top: 0;
	bottom: 0;
	margin: auto 0;
	transition: 0.4s;
	box-shadow: 0 0px 15px #2020203d;
	background: white url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAOfSURBVEiJlZVLaFxVGMd/333NJG2a+ujUJnGSSWljGvPoQ0tBbIooulDoQgSpEou4UOqiLlzpxoVFEIsgaotaaEhBEXVV8EUXgrao0SYxk6ROjCUxfaQvm8fMPfd+LqZJ5k4ySfxW93yP/+Ocw7mwTOhw21odbG8rzuf6N3fl+iqNDm5rXGreKlXwB1oe8vtr0yanF4JY1YeR2lDzbgnHn5JwxvYJjuhwR7wUjixUXBc3Wfsk/lg7sUYkvmXC1vB+qe3K5IZa90s2cxTNWQASa4B4C0AAfO8kOx8pxnMi4OdaEmbmcg9mIoF7NxLfAiIvSLIrAyChjswpsysg3jy7tEEuLeZgbotUsQL/SjdmIoF4SPl2QM7YNce/mO1xG85+p27dy3lp1XMbIPCrfbGsY0kCM7DxWzWXqgCINQIugh4TQSMuYRRAxSnM/Ss7jvglCfy+1gfwx/bMuhWv/lbR/rp4wLZw88qzPYIcBDIscpazkZdhXf2YMLy1DwnIq1Muxv5eMLCp5zPta6qQTb03oRdVDkO7XYpAdLhtrZkcuoKavIp4MxK7ByDrJDtLXr+VhmWy/oE5cECkfPYzpiNP37ZSIE23pLSvyVtAIGR3RTLizn0ay25ZEXjm3vVGL/wWxCrfU22PXH1LMVXR7lzhYu+y4H+1bjWTf45jV6/Bq38+OF/zTYTAEnUjAzozb0bp0H+eW7cUgdT93o274QxeDQChhK9GCELcy5GJILKsDHxzaFkX4qUJp99F5agrzrWIgFy68SPxM/vnMw6y5glg/uYJctBOHn9nOaLCyJ1/ZqeE4WrLldgHUTkGzY1EU+jb/si+t4oPsFSYkX3PWqqnLJGdAmD+SNzU4PqquQ6rDKl4LOIi70T7FN6wA/crSR2bKaypPmmbUfdBCezXEN0DqC1hgwAEg42Hwmwmcjji1UPZ9lIipxB+Ah1FLR90A3AfcGdBz5dOsnOv5Nmxgv7ENQ2uV0RgynYgXmolu1Ic03YQbJPUiXT+xyGEtnPXw1heGG37Bc2e+9/oAq9I6kQaCp5r2Xz2tHqpA4hb8DwrzHSjUz9COL0icEXftJOd7xeQRcMMNT1ObuxzDafcaMVGvFrw6sC6HWTB6DTwkpPs/KTIzSIqhrauM8GNTzHjuwsfwvkpB6w1IHEQVGV1r+vc8aJsPPXDgtaSXgEd2FXtc/V1gslHLabWa5jzEAXsACm/IVbZz8iqw05Dz8lSGP8BjRZeDZkbZsEAAAAASUVORK5CYII=);
	background-repeat: no-repeat;
	background-position: center;
}

input:checked + .slider {
	background-color: #2196f3;
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
	-webkit-transform: translateX(24px);
	transform: translateX(24px);
	background: white url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANGSURBVEiJndVPbNRVEAfwz9vd/q8tBQtoxSYYDKYmejMGD3DxYloMiRoT22IEDQcPno3xoFclMf6JHrDFyIVEI3g2eiDoRWOMfygqNrSkoeAWCtIu230e3m+77e6KCZO8vH3zZuY7M+/7mxVjVFu7C+vPt7mmh4fi+eEDMUYFVZkdeZU7dhgIzxOj25ULe3fJx6PYamZkDYD4rRDmmgafDIdE3aIJbcrKxkQF4/GtBtuY/43KGVBxOsT/S3YijAomEVBCBe0geNlYfLfBZ3bfJmFlp7u/ONUcYCIMCw6jL1vhFikUURS9Zn88Vn9ZaOJA8DTuA3n0Zjm3ZlDL2VrASpZE8CwaABormAgPC75Cny7cmYE0kwou4Rq4IWeP0fhdc4DjIe+GD0QvIKcbmzOrtl56t9O2gRBYvsKVcyz9ne7nsQgiPhUdtD8uNVYwGRbQK497qu3ZzsYHUuB1EimepTiVKplBGZQM6rI7liFX5/Ur0rPmpYw3ZcEL7fRso3eQQgcCfffT0Z+i9K3GOFMNvh7gWOjDYGpJpuvbkQK1dND/IF1b6Oynf4jW7jU2a3zY6uPQ3whw0/u4S5DYIqsAOjdrkKqutSfbVcncL+ejOoAQsC39VGN9yOiTa8LmXIGOx8h11RxqzzTgeHLOAGKUM4pFFdzMzJYX0n79YiPAP/O0PUSlHTE9cCXdCJ7zVFxZA4DReA4/gKVMd/WvtJcWKf6e6FlapPgHS0UW3uPyN9b58IuxOFU91Ne+E+kL7cb1Oa5O0zPI0kJaa+XaLIszif21q4x2if+1ClLP/iRrUfYNufQTF79P2ceY2lFaZP5HLqaCFaUxmOSsT3RWD42j4mh4RnQEnTZYP+pCLh1Se2uZF0FZdNC0o16Plf8GgMnwOZ5Eot9GiefVmbQiDbtitif52njcUx+q+TSNTgoZQAlzddblJj7ByWah0htc2LvL7L5Nq9r98YhBLSo2481Vfbkh+DtytljWaiy+3QwgxOnhIfl4AmdoGTXw2eUGq8lwWNCDD1WUBC+hYNyLTf9iZ554RAiPG/jyjYKcR7E1wa3sxKkGh/H4Sp3mULNsVyVvTgxTqYIYmRk5oOK0e0/8fEvH25B/AZyRWA6LDlu9AAAAAElFTkSuQmCC);
	background-repeat: no-repeat;
	background-position: center;
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

